<template>
	<v-container fluid :class="affichagePortable ? 'pa-0' : ''">
		<v-row justify="center" class="ma-0">
			
            <!-- zone liste des SP -->
            <v-col cols="12" sm="4" class="pa-0" v-if="!affichagePortable">
				<v-select class="mb-2" v-if="acces_autorise(['saisie fmpa hors ssuap', 'saisie fmpa ssuap'])" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" item-value="id" v-model="id_centre_selectionne"></v-select>
				<v-text-field :class="affichagePortable ? 'pa-2' : ''" label="Recherche" dense outlined clearable prepend-inner-icon="mdi-magnify" v-model="texte_recherche" hide-details></v-text-field>
                <v-row class="ma-0" :class="affichagePortable ? 'px-2' : ''" justify="start" align="center">
					<v-radio-group v-model="id_filtrage_fmpa_courant" row dense hide-details :disabled="fmpas_courantes.length == 0">
						<v-radio v-for="categorie in liste_categorie_filtre" :key="categorie.id" :label="categorie.nom" :value="categorie.id"></v-radio>
					</v-radio-group>
				</v-row>
				<v-row class="ma-0" :class="affichagePortable ? 'px-2' : ''" justify="start" align="center">
					<v-radio-group v-model="id_filtrage_statut" row dense hide-details>
						<v-radio v-for="statut in liste_statut_filtre" :key="statut.id" :label="statut.nom" :value="statut.id"></v-radio>
					</v-radio-group>
				</v-row>
				
				<v-toolbar dark flat dense color="blue-grey lighten-1" class="mt-4">
                    <v-toolbar-title>SP</v-toolbar-title>
					<v-slide-x-transition mode="out-in">
						<div class="ml-4 font-italic font-weight-light" :key="texte_filtrage">{{texte_filtrage}}</div>
					</v-slide-x-transition>
                    <v-spacer></v-spacer>
                    <v-chip class="ma-2" color="blue-grey lighten-3">{{ liste_membres_filtree.length }}</v-chip>
                </v-toolbar>
                <v-list v-if="liste_membres_filtree.length > 0" :class="acces_autorise(['saisie fmpa hors ssuap', 'saisie fmpa ssuap']) ? 'liste_membres_choix_centre' : 'liste_membres'" class="pb-0">
                    <template v-for="(membre, index) in liste_membres_filtree">
                        <v-list-item :key="membre.id" ripple @click="onSelectMembre(membre)" :class="couleurMembre(membre)">
                            <v-list-item-avatar color="blue-grey lighten-3">
								<img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
								<span v-else class="white--text text-h6 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
							</v-list-item-avatar>
                            <v-list-item-content>
								<v-list-item-title>{{ membre.user.nom + ' ' +  membre.user.prenom}}</v-list-item-title>
							</v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index + 1 < liste_membres_filtree.length" :key="'divider'+index"></v-divider>
                    </template>
                </v-list>
                <v-row v-else class="ma-0 pa-3" justify="center">
                    <span class="text-subtitle-1 primary--text font-weight-light mt-4">(aucun SP dans le centre)</span>
                </v-row>
            </v-col>

            <!-- zone détail du membre -->
            <v-col cols="12" sm="7" offset-sm="1" class="pa-0">
                <v-row align="center" class="ma-0 mb-4 px-4 mt-4" :class="!affichagePortable ? 'mt-4 mb-8' : ''">
					<v-btn fab outlined small @click="annee_precedente" :loading="chargement_annee_precedente">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" style="cursor: pointer" :key="annee" @click="annee_courante">{{ annee }}</div>
					</v-slide-y-transition>
					<v-btn fab outlined small @click="annee_suivante" :loading="chargement_annee_suivante">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0 mb-6 pr-1" v-if="!affichagePortable"> <!-- en mode portable, les boites de dialogues sont trop petites -->
					<v-spacer/>
					<v-btn depressed class="ml-1 mb-1" color="amber lighten-3" small v-if="acces_autorise(['saisie fmpa hors ssuap', 'saisie fmpa ssuap'], false) && !fmpas_courantes.length == 0" @click="onClickAjoutFMPAMultiple">Saisie multiple</v-btn>
					<!-- <v-btn depressed class="ml-1 mb-1" color="deep-purple lighten-4" small v-if="acces_autorise(['modification fmpa']) && !estAnneePassee && fmpas_courantes.length == 0 && estMemeCentre" @click="onClickCreerFMPA">Créer une FMPA pour {{annee}}</v-btn> -->
					<v-btn depressed class="ml-1 mb-1" color="deep-purple lighten-4" small v-if="acces_autorise(['modification fmpa']) && !estAnneePassee && fmpas_courantes.length > 0 && estMemeCentre" @click="openListeThemeDrawer = true">Ajouter/modifier les thèmes</v-btn>
					<!-- <v-btn depressed class="ml-1 mb-1" color="deep-purple lighten-4" small v-if="acces_autorise(['modification fmpa']) && !estAnneePassee && fmpas_courantes.length > 0 && estMemeCentre" :disabled="!estSansRealisation" @click="onClickSupprFMPA">Supprimer la FMPA {{annee}}</v-btn> -->
					<v-tooltip left v-if="!fmpas_courantes.length == 0">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" outlined class="ml-1 mb-1" color="primary" small :loading="chargement_excel" @click="generer_tableau_excel">
								<v-icon>mdi-microsoft-excel</v-icon>
							</v-btn>
						</template>
						<span>Exporter synthèse</span>
					</v-tooltip>
				</v-row>
				
				
				<v-slide-y-transition mode="out-in">
                    <v-row class="ma-0" v-if="membreCourant != null" :key="membreCourant.id+''+annee_courante_chargee" justify="center">
                        <v-col cols="12" sm="10" class="pa-0 pt-2">
                            <v-row class="ma-0 mb-4" justify="center" align="center" @click="onClickNomMembreDetail">
								<v-avatar size="30" color="blue-grey lighten-3">
									<img v-if="membreCourant.user.photo" :src="getPhoto(membreCourant.user.photo)">
									<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(membreCourant.user.prenom)+premiereLettreMaj(membreCourant.user.nom)}}</span>
								</v-avatar>
								<div class="ml-3 text-uppercase" align="center">{{membreCourant.user.prenom +' '+membreCourant.user.nom}}</div>
								<div class="ml-1 font-italic caption" v-if="membreCourant.user.membres_centre.length > 1">{{ getNomCentre }}</div>
							</v-row>
							<v-row class="ma-0" justify="end" :class="affichagePortable ? 'mr-2' : ''">
								<v-btn-toggle v-model="mode_affichage" dense>
									<v-btn value="groupe">
										<v-icon color="grey darken-1">mdi-menu</v-icon>
									</v-btn>
									<v-btn value="timeline">
										<v-icon color="grey darken-1">mdi-timeline</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-row>
							
							<v-fade-transition mode="out-in">
							<v-expansion-panels v-if="fmpas_courantes_filtree.length > 0 && mode_affichage == 'groupe'" :key="annee_courante_chargee+'_'+mode_affichage" class="mt-2" flat :class="!affichagePortable ? 'contenu_panels_pc':''">
								<v-expansion-panel v-for="fmpa in fmpas_courantes_filtree" :key="fmpa.categorie" class="fmpa_pc_gestion">
									<v-expansion-panel-header hide-actions color="indigo lighten-5" class="pr-4">
										<v-row class="ma-0" align="center">
											<v-icon class="mr-5">{{getIconeCategorie(fmpa.categorie)}}</v-icon>
											<div>{{fmpa.categorie}}</div>
											<v-spacer/>
											<div v-if="fmpa.duree_requise" class="text-caption">{{getDureeRealiseeListe(fmpa.liste)}} / {{getDureePrevueListe(fmpa.liste)}}</div>
											<v-icon class="ml-3" :color="estListeValidee(fmpa.liste) ? 'light-green lighten-1':'red lighten-1'">{{estListeValidee(fmpa.liste) ? 'mdi-check-bold':'mdi-close-thick'}}</v-icon>
										</v-row>
									</v-expansion-panel-header>
									<v-expansion-panel-content class="contenu_liste_theme_fmpa_gestion" color="grey lighten-5">
										<v-list class="liste_theme_fmpa py-0" v-if="fmpa.liste.length > 0">
											<template v-for="(theme, index) in fmpa.liste">
												<v-list-item :key="theme.id">
													<v-list-item-content>
														<v-row class="ma-0">
															<v-col class="pa-0">
																<v-row class="ma-0" align="center">
																	<div>{{theme.titre}}</div>
																	<v-chip label x-small text-color="white" class="font-weight-bold ml-2" color="deep-purple lighten-1" v-if="estSPPSPVonly(theme)">{{theme.pour_spp ? 'SPP' : 'SPV'}}</v-chip>
																	<v-btn x-small icon v-if="acces_theme(theme)" class="ml-1" @click="onClickAjoutFMPAIndiv(theme)">
																		<v-icon size="14">mdi-plus-circle-outline</v-icon>
																	</v-btn>
																</v-row>
																<div class="font-weight-light text-body-2">{{theme.description}}</div>
																<v-row class="ma-0 pl-0" v-for="realisation in theme.realisations" :key="realisation.id" align="center">
																	<template v-if="realisation.membre_centre.id_user == membreCourant.id_user">
																		<v-menu bottom left v-if="(acces_theme(theme) && autorisationModif(realisation)) || (acces_autorise(['validation fmpa hors manoeuvre'], false) && realisation.hors_manoeuvre)">
																			<template v-slot:activator="{ on }">
																				<v-btn x-small icon v-on="on" class="mr-2">
																					<v-icon size="15">mdi-dots-vertical</v-icon>
																				</v-btn>
																			</template>
																			<v-list dense>
																				<v-list-item v-if="acces_autorise(['validation fmpa hors manoeuvre'], false) && realisation.membre_centre.id_centre == centre_courant.id && realisation.hors_manoeuvre" @click="onClickComptabiliserFMPAIndiv(realisation)">
																					<v-list-item-title>{{realisation.hors_manoeuvre_compte ? 'Ne pas prendre en compte':'Prendre en compte'}}</v-list-item-title>
																				</v-list-item>
																				<v-list-item v-if="autorisationModif(realisation)" @click="onClickUpdateFMPAIndiv(realisation, theme)">
																					<v-list-item-title>Modifier</v-list-item-title>
																				</v-list-item>
																				<v-list-item v-if="autorisationModif(realisation)" @click="onClickSupprFMPAIndiv(realisation, theme)">
																					<v-list-item-title>Supprimer</v-list-item-title>
																				</v-list-item>
																			</v-list>
																		</v-menu>
																		<template v-if="theme.type.duree_requise">
																			<v-col class="pa-0">
																				<v-row class="ma-0 mt-2" align="center">
																					<div class="text-caption primary--text font-weight-bold" :class="(acces_theme(theme) && autorisationModif(realisation)) || (acces_autorise(['validation fmpa hors manoeuvre'], false) && realisation.hors_manoeuvre) ? '':'ml-7'">{{formatDuree(realisation.duree_effectuee)}}</div>
																					<div class="text-caption primary--text ml-1">le {{formatDate(realisation.date)}}</div>
																					<div v-if="realisation.saisie_par" class="text-caption primary--text font-weight-light font-italic ml-1">- par {{realisation.saisie_par}}</div>
																					<v-chip class="ml-1" v-if="realisation.membre_centre.id_centre != centre_courant.id" label x-small outlined>{{realisation.membre_centre.centre.nom}}</v-chip>
																				</v-row>
																				<div v-if="realisation.commentaire" class="text-caption primary--text" :class="((acces_theme(theme) && autorisationModif(realisation)) || (acces_autorise(['validation fmpa hors manoeuvre'], false) && realisation.hors_manoeuvre)) ? 'ml-1' : 'ml-7'">({{realisation.commentaire}})</div>
																				<div v-if="realisation.hors_manoeuvre" class="text-caption ml-7 deep-purple--text font-weight-bold"> hors jour de manoeuvre - <span :class="realisation.hors_manoeuvre_compte ? 'light-green--text': 'red--text text--lighten-1'">{{realisation.hors_manoeuvre_compte ? 'pris en compte': 'non pris en compte'}}</span></div>
																			</v-col>
																		</template>
																		<template v-else>
																			<v-col class="pa-0">
																				<v-row class="ma-0 mt-2" align="center">
																					<v-chip label x-small text-color="white" class="font-weight-bold" :color="realisation.est_apte ? 'light-green lighten-1':'red lighten-1'" :class="(acces_theme(theme) && autorisationModif(realisation)) || (acces_autorise(['validation fmpa hors manoeuvre'], false) && realisation.hors_manoeuvre) ? '':'ml-7'">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</v-chip>
																					<!-- <div class="text-caption primary--text font-weight-bold">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</div> -->
																					<div class="text-caption primary--text ml-1">le {{formatDate(realisation.date)}}</div>
																					<div v-if="realisation.saisie_par" class="text-caption primary--text font-weight-light font-italic ml-1">- par {{realisation.saisie_par}}</div>
																					<v-chip class="ml-1" v-if="realisation.membre_centre.id_centre != centre_courant.id" label x-small outlined>{{realisation.membre_centre.centre.nom}}</v-chip>
																				</v-row>
																				<div v-if="realisation.commentaire" class="text-caption primary--text" :class="((acces_theme(theme) && autorisationModif(realisation)) || (acces_autorise(['validation fmpa hors manoeuvre'], false) && realisation.hors_manoeuvre)) ? 'ml-1' : 'ml-7'">({{realisation.commentaire}})</div>
																				<div v-if="realisation.hors_manoeuvre" class="text-caption ml-7 deep-purple--text font-weight-bold"> hors jour de manoeuvre - <span :class="realisation.hors_manoeuvre_compte ? 'light-green--text': 'red--text text--lighten-1'">{{realisation.hors_manoeuvre_compte ? 'pris en compte': 'non pris en compte'}}</span></div>
																			</v-col>
																		</template>
																	</template>
																</v-row>
															</v-col>
														</v-row>
													</v-list-item-content>
													<v-list-item-action>
														<v-row class="ma-0" align="center">
															<v-menu bottom left v-if="listeRealisationsUser(theme).length > 0 && acces_autorise(['forcer validation fmpa'], false) && theme.type.duree_requise && !estDureeSuffisante(theme)">
																<template v-slot:activator="{ on }">
																	<v-btn x-small icon v-on="on" class="mr-2">
																		<v-icon size="15">mdi-dots-vertical</v-icon>
																	</v-btn>
																</template>
																<v-list dense>
																	<v-list-item @click="onClickForcerValidationTheme(theme)">
																		<v-list-item-title>{{estThemeForce(theme) ? 'Ne plus forcer la validation du thème':'Forcer la validation du thème'}}</v-list-item-title>
																	</v-list-item>
																</v-list>
															</v-menu>
															<div v-if="theme.type.duree_requise" class="text-caption">{{getDureeRealiseeTheme(theme)}} / {{formatDureePrevueTheme(theme)}}</div>
															<v-icon class="ml-3" :color="estThemeValide(theme) || estThemeForce(theme) ? 'light-green lighten-1':'red lighten-1'">{{estThemeForce(theme) ? 'mdi-check-all' : (estThemeValide(theme) ? 'mdi-check':'mdi-close')}}</v-icon>
														</v-row>
													</v-list-item-action>
												</v-list-item>
												<v-divider :key="'divider'+index"></v-divider>
											</template>
										</v-list>
										<v-row v-else class="pa-3 my-3" justify="center">
											<div class="text-subtitle-1 font-italic font-weight-light">(aucun thème renseigné)</div>
										</v-row> 
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>

							<div v-else-if="mode_affichage == 'timeline'" :key="annee_courante_chargee+'_'+mode_affichage" :class="!affichagePortable ? 'contenu_panels_pc':''">
								<v-timeline v-if="liste_fmpa_timeline_membre_courant.length > 0" dense align-top>
									<v-timeline-item v-for="date in liste_fmpa_timeline_membre_courant" :key="date.date" small color="deep-purple">
										<v-row class="ma-0">
											<v-col class="pa-0">
												<v-chip small label color="deep-purple lighten-2" text-color="white" align="center">{{formatDate(date.date)}}</v-chip>
												<v-card outlined class="mt-1" v-for="realisation in date.detail" :key="realisation.id" :class="affichagePortable ? 'mr-2':''">
													<v-row class="ma-0">
														<v-col class="pa-0 d-flex flex-column" cols="3">
															<v-row class="ma-0 pa-1" justify="center" align="center" :style="'background-color:'+getCouleurCategorie(realisation.theme.type.sigle)">
																<v-icon v-if="!affichagePortable" color="white" class="mr-3">{{getIconeCategorie(realisation.theme.type.sigle)}}</v-icon>
																<div class="white--text font-weight-bold">{{realisation.theme.type.sigle}}</div>
															</v-row>
															<v-row class="ma-0 pa-1" justify="center" align="center">
																<div v-if="realisation.theme.type.duree_requise" class="">{{formatDuree(realisation.duree_effectuee)}}</div>
																<v-chip v-else label x-small text-color="white" class="font-weight-bold" :color="realisation.est_apte ? 'light-green lighten-1':'red lighten-1'">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</v-chip>
																<!-- <div v-else class="text-caption primary--text font-weight-bold">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</div> -->
															</v-row>
														</v-col>
														<v-col class="pa-1 pl-2">
															<div>{{realisation.theme.titre}}</div>
															<div class="font-weight-light text-body-2">{{realisation.theme.description}}</div>
															<div v-if="realisation.saisie_par" class="text-caption primary--text font-weight-light font-italic ml-1">par {{realisation.saisie_par}}</div>
															<v-chip v-if="realisation.membre_centre.id_centre != centre_courant.id" label x-small outlined>{{realisation.membre_centre.centre.nom}}</v-chip>
															<div v-if="realisation.commentaire" class="font-weight-light font-italic text-caption">({{realisation.commentaire}})</div>
														</v-col>
													</v-row>
												</v-card>
											</v-col>
										</v-row>
									</v-timeline-item>
								</v-timeline> 
								<v-row v-else class="ma-0 mt-12" justify="center">
									<div class="text-subtitle-1 font-italic font-weight-light">(aucune FMPA réalisée)</div>
								</v-row>
							</div>
							
							<v-row v-else class="ma-0 mt-12" justify="center" :key="null+''+annee_courante_chargee">
								<v-col class="pa-0" align="center">
									<div class="text-subtitle-1 font-italic font-weight-light text-center">(Les thèmes ne sont pas renseignés pour cette année)</div>
									<!-- <v-btn outlined color="green" small class="mt-3" v-if="acces_autorise(['modification fmpa']) && !estAnneePassee" @click="onClickCreerFMPA">Créer une FMPA pour {{annee}}</v-btn> -->
								</v-col>
							</v-row>
							</v-fade-transition>
                        </v-col>
                    </v-row>
					<v-row class="ma-0 mt-12" v-else :key="null+''+annee_courante_chargee" justify="center">
						<div v-if="!affichagePortable">Sélectionner un SP dans la liste</div>
						<v-btn small outlined v-else @click="openListeSPVDrawer = true">Sélectionner un SP</v-btn>
					</v-row>
                </v-slide-y-transition>
            </v-col>
		</v-row>

		<!-- drawer liste SP pour portable -->
        <v-navigation-drawer app v-model="openListeSPVDrawer" right width="290" v-if="affichagePortable">
            <v-row class="ma-0">
                <v-col class="pa-0">
					<v-toolbar dark flat dense color="blue-grey lighten-1">
						<v-btn small icon class="ml-0" @click="openListeSPVDrawer = !openListeSPVDrawer">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
						<v-toolbar-title>SP</v-toolbar-title>
						<v-slide-x-transition mode="out-in">
							<div class="ml-4 font-italic font-weight-light" :key="texte_filtrage">{{texte_filtrage}}</div>
						</v-slide-x-transition>
						<v-spacer></v-spacer>
						<v-chip small class="ma-2 mr-0" color="blue-grey lighten-3">{{ liste_membres_filtree.length }}</v-chip>
					</v-toolbar>

					<v-select class="ma-1 mt-2" v-if="acces_autorise(['saisie fmpa hors ssuap', 'saisie fmpa ssuap'])" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" item-value="id" v-model="id_centre_selectionne"></v-select>
					<v-text-field label="Recherche" class="ma-1" dense outlined clearable prepend-inner-icon="mdi-magnify" v-model="texte_recherche" hide-details></v-text-field>
					<v-row class="ma-0" justify="start" align="center">
						<v-radio-group class="ma-0 px-1 mb-1" v-model="id_filtrage_fmpa_courant" row dense hide-details :disabled="fmpas_courantes.length == 0">
							<v-radio v-for="categorie in liste_categorie_filtre" :key="categorie.id" :label="categorie.nom" :value="categorie.id"></v-radio>
						</v-radio-group>
					</v-row>
					<v-row class="ma-0" justify="start" align="center">
						<v-radio-group class="ma-0 px-1 mb-1" v-model="id_filtrage_statut" row dense hide-details>
							<v-radio v-for="statut in liste_statut_filtre" :key="statut.id" :label="statut.nom" :value="statut.id"></v-radio>
						</v-radio-group>
					</v-row>

					<v-list dense v-if="liste_membres_filtree.length > 0" class="pb-0" :class="acces_autorise(['saisie fmpa hors ssuap', 'saisie fmpa ssuap']) ? 'liste_spv_fmpa_portable_choix_centre':'liste_spv_fmpa_portable'">
						<template v-for="(membre, index) in liste_membres_filtree">
							<v-list-item :key="membre.id" ripple @click="onSelectMembre(membre)" :class="couleurMembre(membre)">
								<v-list-item-avatar size="25" color="blue-grey lighten-3">
									<img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
									<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ membre.user.nom + ' ' +  membre.user.prenom}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-divider v-if="index + 1 < liste_membres_filtree.length" :key="'divider'+index"></v-divider>
						</template>
					</v-list>
					<v-row v-else class="ma-0 pa-3" justify="center">
						<span class="text-subtitle-1 primary--text font-weight-light mt-4">(aucun SP dans le centre)</span>
					</v-row>

				</v-col>
            </v-row>
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    
                </v-col>
            </v-row>
        </v-navigation-drawer>

		<!-- drawer pour gestion thèmes du CS -->
        <v-navigation-drawer app clipped v-model="openListeThemeDrawer" temporary stateless right width="500" style="z-index: 5">
            <v-row class="ma-0">
                <v-col class="pa-0">
                    <v-toolbar flat dense color="deep-purple lighten-4">
                        <v-btn small icon class="ml-0" @click="openListeThemeDrawer = !openListeThemeDrawer">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
                        <v-toolbar-title>Thèmes {{annee}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-chip class="ma-2" color="blue-grey lighten-3">{{ getDureePrevueListe(liste_themes_courant)}}</v-chip>
                        <v-btn v-if="acces_autorise(['modification fmpa']) && !estAnneePassee" icon @click="onClickAjoutTheme"><v-icon>mdi-card-plus-outline</v-icon></v-btn>
                    </v-toolbar>
					<div id="zone_liste_drawer">
						<v-card v-for="theme in liste_themes_courant" :key="theme.id" outlined class=" pl-2 ma-2" elevation="1" color="blue-grey lighten-5">
							<v-row class="ma-0 py-1 pt-2">
								<v-col class="pa-0">
									<v-row class="ma-0" align="center">
										<v-chip x-small label text-color="white"  :color="getCouleurCategorie(theme.categorie)">{{theme.categorie}}</v-chip>
												<!-- <v-chip label x-small outlined class="font-weight-bold ml-2" :color="getCouleurCategorie(theme.categorie)" v-if="estSPPSPVonly(theme)">{{theme.pour_spp ? 'SPP' : 'SPV'}}</v-chip> -->
										<div class="ml-2 text--darken-2 grey--text font-weight-bold text-subtitle-2">{{theme.titre}}</div>
										<v-spacer></v-spacer>
										<v-chip label x-small v-if="theme.pour_spp">SPP</v-chip>
										<v-chip label x-small v-if="theme.pour_spv" class="mx-1">SPV</v-chip>
										<v-icon v-if="theme.id_type != 4" size="20" class="mr-1">mdi-clock-time-eight-outline</v-icon>
										<div v-if="theme.id_type != 4" class="text-caption">{{formatDureePrevueTheme(theme)}}</div>
										<v-menu bottom left class="mr-2" v-if="!theme.departemental" z-index="7">
											<template v-slot:activator="{ on }">
												<v-btn small class="ml-2" icon v-on="on">
													<v-icon>mdi-dots-vertical</v-icon>
												</v-btn>
											</template>
											<v-list dense>
												<v-list-item @click="onClickModifTheme(theme)">
													<v-list-item-title>Modifier</v-list-item-title>
												</v-list-item>
												<v-list-item @click="onClickSupprTheme(theme)" :disabled="theme.nbre_realisations > 0">
													<v-list-item-title>Supprimer</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
										<v-icon class="ml-2 mr-2" size="20" v-else>mdi-lock</v-icon>
									</v-row>
									<div class="text-caption mt-2">{{theme.description}}</div>
								</v-col>
							</v-row>
						</v-card>
					</div>
				</v-col>
            </v-row>
        </v-navigation-drawer>

		<!-- dialogue création/update FMPA -->
        <v-dialog v-model="openDialogueCreerFMPA" persistent max-width="1200px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{formulaireFMPA.titre+' '+annee}}</v-card-title> 
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-4 pb-0 mb-2">
                    <v-form class="mt-3">
                        <v-row class="ma-0 mb-4">
							<v-spacer/>
							<v-btn outlined x-small color="green" @click="onClickAjoutTheme">+ Ajouter un thème</v-btn>
						</v-row>
						<div class="zone_liste_theme_dialogue_update">
							<v-row v-for="(theme, index) in $v.formulaireFMPA.themes.$each.$iter" :key="index" class="ma-0 mt-2" align="center">
								<v-select outlined dense hide-details :items="categoriesFMPA" label="Catégorie" v-model="theme.id_type.$model" :error-messages="!theme.id_type.required ? ['Obligatoire']:[]" item-value="id" item-text="sigle" style="max-width: 120px" :disabled="id_fmpa_courante !== null && theme.id.$model !== null"></v-select>
								<v-text-field class="ml-1" outlined dense hide-details label="Titre" v-model.trim="theme.titre.$model" :error-messages="!theme.titre.required ? ['Obligatoire']:[]" style="max-width: 200px"></v-text-field>
								<v-text-field class="ml-1" outlined dense hide-details label="Description" v-model.trim="theme.description.$model" :error-messages="!theme.description.required ? ['Obligatoire']:[]"></v-text-field>
								<v-text-field v-if="theme.id_type.$model != 4" outlined dense hide-details label="Durée" v-model.trim="theme.duree_prevue.$model" :error-messages="!(theme.duree_prevue.required && theme.duree_prevue.formatHeure) ? ['Obligatoire']:[]" class="ml-1" style="max-width: 70px"></v-text-field>
								<v-checkbox hide-details dense class="ma-0 ml-2" v-model="theme.pour_spp.$model" label="SPP"></v-checkbox>
								<v-checkbox hide-details dense class="ma-0 ml-3" v-model="theme.pour_spv.$model" label="SPV"></v-checkbox>
								<v-btn text icon color="secondary" class="ml-2" @click="onClickDeleteTheme(theme, index)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-row>
						</div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleCreerFMPA">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="creerModifierFMPA" :loading="chargement_creer_FMPA" :disabled="estFormulaireCreerFMPAInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>

		<!-- dialogue création/update réalisation FMPA -->
        <v-dialog v-model="openDialogueRealisationFMPA" persistent max-width="450px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        {{formulaireRealisationFMPA.titre}} 
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-4 pb-0 mb-2">
                    <v-form class="mt-4">
						<v-row class="ma-0" justify="center" align="center">
							<v-col class="pa-0" cols="6">
								<v-text-field v-if="formulaireRealisationFMPA.id_type !=4" outlined hide-details dense label="Durée" v-model.trim="$v.formulaireRealisationFMPA.duree.$model" 
									:error-messages="!($v.formulaireRealisationFMPA.duree.required && $v.formulaireRealisationFMPA.duree.formatHeure) ? ['Obligatoire']:[]" style="max-width: 80px">
								</v-text-field>
								<v-btn-toggle v-else dense v-model="formulaireRealisationFMPA.est_apte">
									<v-btn :value="true" active-class="bouton_apte">APTE</v-btn>
									<v-btn :value="false" active-class="bouton_inapte">INAPTE</v-btn>
								</v-btn-toggle>
								
								<!-- <v-switch class="ma-0 ml-2" v-else 
									inset
									dense
									hide-details
									v-model="formulaireRealisationFMPA.est_apte" 
									:label="formulaireRealisationFMPA.est_apte ? 'APTE' : 'INAPTE'"
									color="success">
								</v-switch> -->
							</v-col>
							<v-col class="pa-0" cols="6">
								<v-menu v-model="menu_date_realisation" :close-on-content-click="false" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on }">
										<v-text-field outlined dense class="ml-3"
											:value="formatDate(formulaireRealisationFMPA.date)"
											label="Fait le"
											style="max-width: 150px"
											hide-details
											readonly
											prepend-inner-icon="mdi-calendar"
											v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="formulaireRealisationFMPA.date" :max="today" :first-day-of-week="1" no-title @input="menu_date_realisation = false"></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
						<v-row class="ma-0 mt-5">
							<v-textarea outlined hide-details height="100" dense label="Commentaire" v-model.trim="formulaireRealisationFMPA.commentaire"></v-textarea>
						</v-row>
						<!-- Si SPV on affiche le choix hors manoeuvre -->
						<v-row class="ma-0 mt-3" v-if="membreCourant && membreCourant.statut == 0">
							<v-switch class="ma-0 ml-2"
								inset
								dense
								hide-details
								v-model="formulaireRealisationFMPA.hors_manoeuvre" 
								label="Réalisé hors jour de manoeuvre">
							</v-switch>
						</v-row>
                    </v-form>
                </v-card-text> 
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleRealisationFMPA">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="creerModifierRealisationFMPA" :loading="chargement_realisation_FMPA" :disabled="estFormulaireRealisationFMPAInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card>
        </v-dialog>

		<!-- dialogue création réalisation FMPA Multiple -->
        <v-dialog v-model="openDialogueRealisationFMPAMultiple" persistent height="80%" width="85%" max-width="1500px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Saisie multiple FMPA {{annee}}</v-card-title> 
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-4 pb-0 mb-2">
                    <v-form class="mt-4">
						<v-row class="ma-0">
							<v-col class="pa-0 pr-2" style="max-width: 350px">
								<v-autocomplete v-model="formulaireRealisationFMPAMultiple.liste_membres"
									:items="liste_membres_saisie_multiple"
									outlined
									dense
									chips
									small-chips
									label="SP"
									multiple
									return-object
									hide-details
									deletable-chips
									single-line
									:search-input.sync="searchInput"
									@change="searchInput=''"
									:item-text="texte_membre"
									style="max-width: 350px">
									<template v-slot:selection="{ item, index }">
										<v-chip small v-if="index === 0">
											<span>{{ item.user.nom + ' ' + item.user.prenom }}</span>
										</v-chip>
										<span v-if="index === 1" class="grey--text caption">(+{{ formulaireRealisationFMPAMultiple.liste_membres.length - 1 }} autres)</span>
									</template>
								</v-autocomplete>
								<v-row class="ma-0 mt-2" justify="center">
									<div class="text-overline"><span class="deep-purple--text font-weight-bold">{{liste_spv_saisie_multiple_triee.length > 0 ? liste_spv_saisie_multiple_triee.length : ''}}</span> SP sélectionnés</div>
								</v-row>
								<div class="liste_membres_saisie_multiple pr-2">
									<v-list v-if="liste_spv_saisie_multiple_triee.length > 0">
										<template v-for="(membre, index) in liste_spv_saisie_multiple_triee">
											<v-list-item :key="membre.id">
												<v-list-item-avatar color="blue-grey lighten-3">
													<img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
													<span v-else class="white--text text-h6 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title>{{ membre.user.nom + ' ' +  membre.user.prenom}}</v-list-item-title>
												</v-list-item-content>
												<v-list-item-action>
													<v-btn icon small class="ml-2" @click="onClickDeleteSPVListe(membre)"><v-icon size="20">mdi-delete</v-icon></v-btn>
												</v-list-item-action>
											</v-list-item>
											<v-divider v-if="index + 1 < liste_membres_filtree.length" :key="'divider'+index"></v-divider>
										</template>
									</v-list>
									
									<div v-else class="font-italic text-center my-5">Aucun SP sélectionné</div>
								</div>
							</v-col>

							<v-col class="pa-0 px-2" style="max-width: 370px">
								<v-row class="ma-0 mt-2 mb-2" justify="center">
									<div class="text-overline">Thèmes disponibles</div>
								</v-row>
								<div v-if="liste_themes_fmpa_disponibles.length > 0" class="zone_theme_saisie px-1">
									<Container group-name="themes" @drop="onDropTheme" :get-child-payload="getChildPayloadTheme">
										<Draggable v-for="theme in liste_themes_fmpa_disponibles" :key="theme.id">
											<v-card  outlined class="mb-2" elevation="1" color="blue-grey lighten-5">
												<v-list-item two-line dense>
													<v-list-item-content class="pa-0">
														<v-row class="ma-0 py-1 pt-2">
															<v-col class="pa-0">
																<v-row class="ma-0" align="center">
																	<v-chip x-small label text-color="white"  :color="getCouleurCategorie(theme.categorie)">{{theme.categorie}}</v-chip>
																	<v-chip label x-small outlined class="font-weight-bold ml-2" :color="getCouleurCategorie(theme.categorie)" v-if="estSPPSPVonly(theme)">{{theme.pour_spp ? 'SPP' : 'SPV'}}</v-chip>
																	<div class="ml-2 text--darken-2 grey--text font-weight-bold text-subtitle-2">{{theme.titre}}</div>
																	<v-spacer></v-spacer>
																	<v-chip v-if="!theme.departemental" label x-small color="blue lighten-3" class="mr-2">CS</v-chip>
																	<div v-if="theme.id_type != 4" class="text-caption">{{formatDureePrevueTheme(theme)}}</div>
																	
																</v-row>
																<div class="text-caption">{{theme.description}}</div>
															</v-col>
														</v-row>
													</v-list-item-content>
												</v-list-item>
											</v-card>
										</Draggable>
									</Container>
								</div>
								<div v-else class="font-italic text-center my-5">Aucun thème disponible</div>

							</v-col>

							<v-col class="pa-0 pl-2">
								<v-row class="ma-0 mt-2" justify="center">
									<div class="text-overline">Thèmes à valider</div>
								</v-row>
								<v-row class="ma-0" justify="center">
									<div v-if="formulaireRealisationFMPAMultiple.liste_realisations.length == 0" class="font-italic text-center">Glissez-déposez les thèmes de la liste de gauche ici.</div>
								</v-row>
								<Container group-name="themes" class="zone_realisation_fmpa_multiple pr-2 mt-2" @drop="onDrop" :get-child-payload="getChildPayloadRealisation" non-drag-area-selector=".ne_pas_deplacer">
									<Draggable v-for="(realisation, index) in $v.formulaireRealisationFMPAMultiple.liste_realisations.$each.$iter" :key="index">
											<v-card  outlined class="mb-2 pa-0">
												<v-row class="ma-0 pa-2" align="center" :style="'background-color: '+getCouleurCategorie(realisation.$model.theme.categorie)">
													<div class="ml-1 white--text font-weight-bold">{{realisation.$model.theme.categorie}}</div>
													<v-divider dark class="mx-4" vertical></v-divider>
													<v-chip label small outlined class="font-weight-bold" color="white" v-if="estSPPSPVonly(realisation.$model.theme)">{{realisation.$model.theme.pour_spp ? 'SPP' : 'SPV'}}</v-chip>
													<div class="ml-2 white--text text-subtitle-2">{{realisation.$model.theme.titre}}</div>
													<v-spacer></v-spacer>
													<v-btn small text icon color="secondary" class="ml-2" @click="onClickDeleteRealisation(realisation.$model.theme, index)">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</v-row>
												<v-row class="ma-0 py-1 pa-2">
													<div>{{realisation.$model.theme.description}}</div>
												</v-row>
												<v-row class="ma-0 pa-2" align="center">
														<v-col class="pa-0" cols="6">
															<v-row class="ma-0" align="center">
																<v-col class="pa-0" cols="6">
																	<v-text-field v-if="realisation.$model.theme && realisation.$model.theme.id_type !== 4" outlined dense hide-details class="ne_pas_deplacer" label="Durée" v-model.trim="realisation.duree.$model" :error-messages="!(realisation.duree.required && realisation.duree.formatHeure) ? ['Obligatoire']:[]" style="max-width: 70px"></v-text-field>
																	<v-btn-toggle v-else dense v-model="realisation.$model.est_apte">
																		<v-btn :value="true" active-class="bouton_apte">APTE</v-btn>
																		<v-btn :value="false" active-class="bouton_inapte">INAPTE</v-btn>
																	</v-btn-toggle>
																</v-col>
																<v-col class="pa-0" cols="6">
																	<v-menu v-model="realisation.ouverture_menu_date" :close-on-content-click="false" transition="scale-transition" offset-y>
																		<template v-slot:activator="{ on }">
																			<v-text-field outlined dense class="ml-3"
																				:value="formatDate(realisation.date.$model)"
																				label="Fait le"
																				style="max-width: 150px"
																				hide-details
																				readonly
																				prepend-inner-icon="mdi-calendar"
																				v-on="on"></v-text-field>
																		</template>
																		<v-date-picker v-model="realisation.date.$model" :max="today" :first-day-of-week="1" no-title @input="realisation.ouverture_menu_date = false"></v-date-picker>
																	</v-menu>
																</v-col>
															</v-row>
															<v-row class="ma-0 mt-2" align="center">
																<!-- Si un SPV dans la liste on affiche le choix hors manoeuvre -->
																<v-switch v-if="presenceSPV" class="ma-0 pl-1"
																	inset
																	dense
																	hide-details
																	v-model="realisation.$model.hors_manoeuvre" 
																	label="Réalisé hors jour de manoeuvre (pour SPV)">
																</v-switch>
															</v-row>
														</v-col>
														<v-col class="pa-0" cols="6">
															<v-textarea class="ml-3 ne_pas_deplacer" outlined hide-details height="100" dense label="Commentaire" v-model.trim="realisation.commentaire.$model"></v-textarea>
														</v-col>
														
													</v-row>
											</v-card>
									</Draggable>
								</Container>
							</v-col>
						</v-row>
                    </v-form>
                </v-card-text> 
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleRealisationFMPAMultiple">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="creerRealisationFMPAMultiple" :loading="chargement_realisation_FMPAMultiple" :disabled="estFormulaireRealisationFMPAMultipleInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card>
        </v-dialog>

		<!-- dialogue création/update thème -->
        <v-dialog v-model="openDialogueCreerTheme" persistent max-width="800px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{formulaireTheme.id == null ? 'Ajouter un thème' : 'Modifier le thème ' + formulaireTheme.titre}}</v-card-title> 
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-4 pb-0 mb-2">
                    <v-form class="mt-3">
						<v-row class="ma-0">
							<v-select outlined dense hide-details :items="categoriesFMPA" label="Catégorie" v-model="$v.formulaireTheme.id_type.$model" :error-messages="erreursCategorie" item-value="id" item-text="sigle" style="max-width: 120px"></v-select>
							<v-text-field class="ml-2" outlined dense hide-details label="Titre" v-model.trim="$v.formulaireTheme.titre.$model" :error-messages="erreursTitre"></v-text-field>
							<v-text-field v-if="$v.formulaireTheme.id_type.$model != 4" outlined dense hide-details label="Durée" v-model.trim="$v.formulaireTheme.duree_prevue.$model" :error-messages="!($v.formulaireTheme.duree_prevue.required && $v.formulaireTheme.duree_prevue.formatHeure) ? ['Obligatoire']:[]" class="ml-1" style="max-width: 70px"></v-text-field>
						</v-row>
						<v-row class="ma-0 mt-4">
							<v-text-field outlined dense hide-details label="Description" v-model.trim="$v.formulaireTheme.description.$model" :error-messages="erreursDescription"></v-text-field>
						</v-row>
						<v-row class="ma-0 mt-2">
							<v-checkbox hide-details dense class="ma-0" v-model="formulaireTheme.pour_spp" label="SPP"></v-checkbox>
							<v-checkbox hide-details dense class="ma-0 ml-3" v-model="formulaireTheme.pour_spv" label="SPV"></v-checkbox>
						</v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleCreerTheme">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="creerModifierTheme" :loading="chargement_creer_theme" :disabled="estFormulaireCreerThemeInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>

		<!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueConfirmation" max-width="290" persistent>
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{ dialogue_confirmation.titre }}</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">{{ dialogue_confirmation.texte }}</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleConfirmation">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="validConfirmation" :loading="chargement_confirmation">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
  
</template>

<script>
	import moment from 'moment'
    moment.locale('fr')
	import axios from 'axios'
	import { groupBy, sortBy } from 'lodash'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import saveAs from 'file-saver'
	import { Container, Draggable } from 'vue-smooth-dnd'
	export default {
		components: {
            Container,
            Draggable
        },
		created() {
			this.$store.commit('majTitrePage', 'FMPA')
		},
		mounted() {
			this.$store.dispatch('getListeCentres').then( () => {
				//	initialisation du centre
				this.id_centre_selectionne = this.$store.getters.membreCourant.id_centre
                this.$store.dispatch('getAnnuaire', this.id_centre_selectionne)
				this.$store.dispatch('getCategoriesFMPA')
				this.annee = parseInt(moment().format('YYYY'))
				this.fetchFMPA(this.annee)
                
			})
			
		},
		data() {
			return {
				annee: null,
				annee_courante_chargee: null,	//sert à charger l'annee apres le fetch pour la transition fade au bon moment
				chargement_annee_precedente: false,
				chargement_annee_suivante: false,
				chargement_confirmation: false,
				chargement_creer_FMPA: false,
				chargement_creer_theme: false,
				chargement_excel: false,
				chargement_realisation_FMPA: false,
				chargement_realisation_FMPAMultiple: false,
				dialogue_confirmation: {
                    titre: '',
                    texte: '',
					action: '',
					id_element: null
                },
				fmpas_courantes: [],
				fmpas_courantes_timeline_globale: [],
				formulaireFMPA: {
					titre: '',
					id_fmpa: null,
					id_membre: null,
					id_centre: null,
					ids_themes_supprimes: [],
					themes: []		// contiendra une liste de {id: xx, id_type: xx, titre: xx, description: xx, duree_prevue: xx, pour_spv: true/false, pour_spp: true/false}
				},
				formulaireRealisationFMPA: {
					id_realisation: null,
					id_theme: null,
					id_type: null,
					duree: null,
					date: null,
					commentaire: null,
					hors_manoeuvre: false,
					titre: '',
					est_apte: undefined
				},
				formulaireRealisationFMPAMultiple: {
					id_fmpa: null,
					liste_membres: [],
					liste_realisations: []	// collection de {id_theme: xx, date: xx, duree: xx, commentaire: xx }
				},
				formulaireTheme: {
					id: null,
					id_type: null,
					titre: '',
					description: '',
					duree_prevue: '00:00',
					pour_spv: false,
					pour_spp: false,
					centres: []
				},
				id_centre_selectionne: this.$store.getters.membreCourant.id_centre,
				id_filtrage_fmpa_courant: 'global',
				id_filtrage_statut: 'tous',
				id_fmpa_courante: null,
				liste_categorie_filtre: [
					{
						id: 'global',
						nom: 'Global'
					},
					{
						id: 'CP',
						nom: 'CP'
					},
					{
						id: 'DIV',
						nom: 'DIV'
					},
					{
						id: 'INC',
						nom: 'INC'
					},
					{
						id: 'SR',
						nom: 'SR'
					},
					{
						id: 'SSUAP',
						nom: 'SSUAP'
					},
				],
				liste_themes_fmpa_disponibles: [],
				liste_statut_filtre: [
					{
						id: 'tous',
						nom: 'Tous'
					},
					{
						id: 'spv',
						nom: 'SPV'
					},
					{
						id: 'spp',
						nom: 'SPP'
					}
				],
				liste_themes_courant: [],
				membreCourant: null,
				menu_date_realisation: false,
				mode_affichage: 'groupe',
				openDialogueConfirmation: false,
				openDialogueCreerFMPA: false,
				openDialogueCreerTheme: false,
				openDialogueRealisationFMPAMultiple: false,
				openDialogueRealisationFMPA: false,
				openListeSPVDrawer: false,
				openListeThemeDrawer: false,
				searchInput: '',
				texte_recherche: '',
				today: moment().format('YYYY-MM-DD')
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			erreursCategorie() {
                const errors = []
                if (!this.$v.formulaireTheme.id_type.$dirty) return errors
                !this.$v.formulaireTheme.id_type.required && errors.push('La catégorie est obligatoire')
                return errors
			},
			erreursDescription() {
				const errors = []
                if (!this.$v.formulaireTheme.description.$dirty) return errors
                !this.$v.formulaireTheme.description.required && errors.push('La description est obligatoire')
                return errors
			},
			erreursTitre() {
                const errors = []
                if (!this.$v.formulaireTheme.titre.$dirty) return errors
                !this.$v.formulaireTheme.titre.required && errors.push('Le titre est obligatoire')
                return errors
            },
			estAnneePassee() {
				//	renvoie true si l'année est terminee
				const dernier_jour = moment(this.annee, 'YYYY').endOf('year')
				return moment().isAfter(dernier_jour)
			},
			estFormulaireCreerFMPAInvalid() {
				return this.$v.formulaireFMPA.$invalid
			},
			estFormulaireCreerThemeInvalid() {
				return this.$v.formulaireTheme.$invalid
			},
			estFormulaireRealisationFMPAMultipleInvalid() {
				return this.$v.formulaireRealisationFMPAMultiple.$invalid
			},
			estFormulaireRealisationFMPAInvalid() {
				return this.$v.formulaireRealisationFMPA.$invalid
			},
			estMemeCentre() {
				// renvoie true si le centre sélectionné est identique au centre du membre courant. Utiliser pour empecher de modifier les FMPA d'un autre centre
				return this.id_centre_selectionne == this.centre_courant.id
			},
			estSansRealisation() {
				//	renvoie true s'il n'y a aucune réalisation dans aucun thème dans aucune catégorie de la FMPA courante. uyilisé pour éviter d'effacer parm mégarde
				//	une FMPA déja renseignée (pour la supprimer il faudra effacer les réalisations 1 à 1)
				let est_vide = true
				for (const categorie of this.fmpas_courantes) {
					for (const theme of categorie.liste) {
						if(theme.realisations.length > 0) est_vide = false
					}
				}
				return est_vide
			},
			categoriesFMPA() {
				return this.$store.getters.categoriesFMPA
			},
			fmpas_courantes_filtree() {
				const liste_complete = this.fmpas_courantes
				if(!this.membreCourant) return liste_complete
				
				//	si membre courant = SPP
				if(this.membreCourant.statut == 1){
					return liste_complete.map( element => {
						return {...element, liste: element.liste.filter(theme => theme.pour_spp)}
					})
				}
				//	si membre courant = SPV
				else if(this.membreCourant.statut == 0) {
					return liste_complete.map( element => {
						return {...element, liste: element.liste.filter(theme => theme.pour_spv)}
					})
				}
				else {
					return liste_complete
				}
				
			},
			getNomCentre() {
				//	retourne le nom du centre du SP pour le membre visualisé si celui-ci a plusieurs affectations
				if(!this.membreCourant) return ''
				const membre = this.membreCourant.user.membres_centre.find( membre => membre.id_centre == this.id_centre_selectionne)
				if (membre) {
					return '('+membre.centre.nom+')'
				}
				return ''
			},
			listeCentres() {
				let liste_complete = this.$store.getters.liste_centres
				return liste_complete.filter( centre => centre.departement == this.centre_courant.departement)
			},
			liste_fmpa_timeline_membre_courant() {
				if(!this.membreCourant) return []
				const liste_filtree = this.fmpas_courantes_timeline_globale.filter(realisation => realisation.membre_centre.id_user == this.membreCourant.id_user)
				const fmpa_timeline_groupee = groupBy(liste_filtree, (realisation) => realisation.date)
				let liste_fmpa_timeline = []
				for (const [key, realisation] of Object.entries(fmpa_timeline_groupee)) {
					liste_fmpa_timeline.push({
						date: key,
						detail: realisation
					})
				}
				return liste_fmpa_timeline
			},
			liste_membres() {
				return this.$store.getters.annuaire.filter( agent => agent.statut == 0 || agent.statut == 1)
			},
			liste_membres_filtree() {
				
				let liste_complete = this.liste_membres
				switch (this.id_filtrage_statut) {
					case 'spv':
						liste_complete = this.liste_membres.filter( agent => agent.statut == 0)
						break;
					case 'spp':
						liste_complete = this.liste_membres.filter( agent => agent.statut == 1)
						break;
					default:
						break;
				}

				if(this.texte_recherche){
                    return liste_complete.filter( element => {
                        return (
								element.user.nom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                            || element.user.prenom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                        )
                    })
                }
                return liste_complete
			},
			liste_membres_saisie_multiple() {
				const liste_complete = this.$store.getters.annuaire.filter( agent => agent.statut == 0 || agent.statut == 1)
				const liste_sans_equipe = liste_complete.filter(element => element.equipe == null)
				const liste_avec_equipe = liste_complete.filter(element => element.equipe != null)
				const liste_avec_equipe_filtree = sortBy(liste_avec_equipe, [function(membre) { return membre.equipe.nom + (membre.equipe.est_spp ? ' SPP' : ' SPV') }])
				const liste_avec_equipe_groupee = groupBy(liste_avec_equipe_filtree, (element) => element.equipe.nom + (element.equipe.est_spp ? ' SPP' : ' SPV'))

				let liste_finale = []
				for (const [key, membres] of Object.entries(liste_avec_equipe_groupee)) {
					liste_finale.push({
						header: key
					})
					for (const membre of membres) {
						liste_finale.push(membre)
					}
				}

				//	ajout des membres sans équipe
				if(liste_sans_equipe.length > 0) {
					liste_finale.push({
						header: 'Sans équipe'
					})
					for (const membre of liste_sans_equipe) {
						liste_finale.push(membre)
					}
				}
				
				
				return liste_finale
			},
			liste_spv_saisie_multiple_triee() {
				//	trie la liste du formulaire par ordre alphabetique
				return this.formulaireRealisationFMPAMultiple.liste_membres.sort((a,b) => {
					if(a.user.nom.toLowerCase() < b.user.nom.toLowerCase()) {
						return -1
					}
					else if(a.user.nom.toLowerCase() > b.user.nom.toLowerCase()) {
						return 1
					}

					//	ici on a le même nom donc on tri par prénom
					if(a.user.prenom.toLowerCase() < b.user.prenom.toLowerCase()) {
						return -1
					}
					else if(a.user.prenom.toLowerCase() > b.user.prenom.toLowerCase()) {
						return 1
					}
					return 0
				})
			},
			liste_themes_fmpa_courante() {
				if(!this.fmpas_courantes) return []
				let liste = []
				for (const categorie of this.fmpas_courantes) {
					for (const theme of categorie.liste) {
						
						if(this.acces_theme(theme)) {
						
							liste.push({
								id_theme: theme.id,
								id_type: theme.id_type,
								categorie: categorie.categorie,
								titre: theme.titre,
								description: theme.description,
								departemental: theme.departemental,
								pour_spv: theme.pour_spv,
								pour_spp: theme.pour_spp,
								ouverture_menu_date: false,
								duree_prevue: theme.duree_prevue
							})
						}
					}
				}

				return liste
			},
			presenceSPV() {
				// retourne true si au moins un SPV est dans la liste des bénéficiaires de la saisie multiple
				const liste = this.formulaireRealisationFMPAMultiple.liste_membres
				if(liste.length > 0) {
					let spv_present = false
					for (const membre of liste) {
						if(membre.statut == 0) {
							spv_present = true
						}
					}
					return spv_present
				}
				else {
					return false
				}
			},
			texte_filtrage() {
				let filtrage_statut = ''
				let filtrage_categorie = ''
				let texte_union =''
				switch (this.id_filtrage_statut) {
					case 'spv':
						filtrage_statut = 'SPV'
						break;
					case 'spp':
						filtrage_statut = 'SPP'
						break
					default:
						break;
				}

				if(this.id_filtrage_fmpa_courant != 'global') {
					filtrage_categorie = this.id_filtrage_fmpa_courant
				}

				if(this.id_filtrage_fmpa_courant != 'global' && this.id_filtrage_statut != 'tous') {
					texte_union = ' - '
				}

					
				return this.id_filtrage_fmpa_courant == 'global' && this.id_filtrage_statut == 'tous' ? '' : '(filtrage : '+filtrage_statut + texte_union + filtrage_categorie+')'
			}
		},
		methods: {
			acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			acces_theme(theme) {
				//	renvoie true si l'utilisateur a le droit de modifier les realisations FMPA de ce theme
				
				//	si theme SSUAP
				if(theme.id_type == 4 && this.acces_autorise(['saisie fmpa ssuap'], false)) {
					return true
				}
				//	si theme hors SSUAP
				else if(theme.id_type != 4 && this.acces_autorise(['saisie fmpa hors ssuap'], false)) {
					return true
				}
				return false
			},
			annee_courante() {
				//this.annee = parseInt(moment().format('YYYY'))
				this.fetchFMPA(parseInt(moment().format('YYYY')))
			},
			annee_precedente() {
				//this.annee -= 1
				this.chargement_annee_precedente = true
				this.fetchFMPA(this.annee - 1)
			},
			annee_suivante() {
				//this.annee += 1
				this.chargement_annee_suivante = true
				this.fetchFMPA(this.annee + 1)
			},
			annuleConfirmation() {
                this.dialogue_confirmation.titre = ''
                this.dialogue_confirmation.texte = ''
				this.dialogue_confirmation.action = ''
				this.dialogue_confirmation.id_element = null
                this.openDialogueConfirmation = false
			},
			autorisationModif(realisation) {
				// renvoie true si la réalisation a été saisie par l'utilisateur courant
				return this.$store.getters.utilisateurCourant.id == realisation.id_saisie_par
			},
			couleurMembre(membre) {
				let est_selectionne = (this.membreCourant != null && this.membreCourant.id == membre.id) ? true : false
				if(est_selectionne) return 'membreSelectionne'
				if(this.fmpas_courantes.length == 0) return ''
                return this.estFMPAMembreOk(membre) ? 'membreFMPA_ok': 'membreFMPA_nok'
			},
			creerModifierFMPA() {
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaireFMPA.$invalid) {
					this.chargement_creer_FMPA = true

					let chemin = ''
					let message_erreur = ''
					if(this.id_fmpa_courante) {
						chemin = 'api/fmpas/update'
						message_erreur = 'Erreur lors de la modification de la FMPA'
					}
					else {
						chemin = 'api/fmpas/creation'
						message_erreur = 'Erreur lors de la création de la FMPA'
					}

					axios.post(chemin, { 
						id_membre: this.$store.getters.membreCourant.id,
						annee: this.annee,
						...this.formulaireFMPA
						})
						.then((reponse) => {
							this.snackbar('success',reponse.data.resultat);
							this.fetchFMPA(this.annee)
							
						})
						.catch((error) => {
							this.snackbar('error', message_erreur);
						})
						.then(() => {
							this.chargement_creer_FMPA = false
							this.onClickAnnuleCreerFMPA()
						})
				}
			},
			creerModifierTheme() {
				// envoi vers serveur, avec l'id de la fmpa courante pour créer la FMPA en bdd si elle n'existe pas
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaireTheme.$invalid) {
					this.chargement_creer_theme = true
					let chemin = ''
					let message = ''
					if(this.formulaireTheme.id) {
						//	si id non null c'est une modif
						chemin = 'api/fmpas/modif_theme_cs'
						message = 'Erreur lors de la modification'
					}
					else {
						chemin = 'api/fmpas/ajout_theme_cs'
						message = 'Erreur lors de l\'ajout'
					}
					
					axios.post(chemin, {
						id_fmpa: this.id_fmpa_courante,
						annee: this.annee,
						id_membre: this.$store.getters.membreCourant.id,
						id_centre: this.id_centre_selectionne,
						...this.formulaireTheme
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
						this.fetchFMPA(this.annee)
                        
		            })
                    .catch((error) => {
                        this.snackbar('error', message);
                    })
                    .then(() => {
						this.chargement_creer_theme = false
						this.onClickAnnuleCreerTheme()
                    })
                }
			
			},
			creerModifierRealisationFMPA() {
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaireRealisationFMPA.$invalid) {
					this.chargement_realisation_FMPA = true

					let chemin = ''
					let message_erreur = ''
					if(this.formulaireRealisationFMPA.id_realisation) {
						chemin = 'api/fmpas/update_realisation'
						message_erreur = 'Erreur lors de la modification de la ligne'
					}
					else {
						chemin = 'api/fmpas/creation_realisation'
						message_erreur = 'Erreur lors de la création de la ligne'
					}

					axios.post(chemin, { 
						id_membre: this.$store.getters.membreCourant.id,
						id_membre_concerne: this.membreCourant.id,
						...this.formulaireRealisationFMPA
						})
						.then((reponse) => {
							this.snackbar('success',reponse.data.resultat);
							this.fetchFMPA(this.annee)
							
						})
						.catch((error) => {
							this.snackbar('error', message_erreur);
						})
						.then(() => {
							this.chargement_realisation_FMPA = false
							this.onClickAnnuleRealisationFMPA()
						})
				}
			},
			creerRealisationFMPAMultiple() {
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaireRealisationFMPAMultiple.$invalid) {
					this.chargement_realisation_FMPAMultiple = true

					axios.post('api/fmpas/creation_realisation_multiple', { 
						id_membre: this.$store.getters.membreCourant.id,
						...this.formulaireRealisationFMPAMultiple
						})
						.then((reponse) => {
							this.snackbar('success',reponse.data.resultat);
							this.fetchFMPA(this.annee)
						})
						.catch((error) => {
							this.snackbar('error', 'Erreur lors de la création des lignes');
						})
						.then(() => {
							this.chargement_realisation_FMPAMultiple = false
							this.onClickAnnuleRealisationFMPAMultiple()
						})
				}
			},
			estDureeSuffisante(theme, membre = null) {
				//	renvoit true si la durée effectuée est > à la durée requise
				//	on définit le membre concerné
				const membre_a_utiliser = membre ? membre : this.membreCourant

				//	on renvoie true si le theme ne concerne pas le membre
				if((!theme.pour_spp && membre_a_utiliser.statut == 1) || (!theme.pour_spv && membre_a_utiliser.statut == 0)) {
					return true
				}

				//	arrivé ici le thème concerne le membre

				//	si le theme requiert une durée
				if(theme.type.duree_requise) {
					const duree_prevue = moment.duration(theme.duree_prevue).asMinutes()	// durée en minutes
					const duree_realisee = moment.duration(this.getDureeRealiseeTheme(theme, membre)).asMinutes()
					return  duree_realisee >= duree_prevue
				}

				
			},
			estFMPAMembreOk(membre) {
				//	renvoie true si la categorie filtree est valide
				if(this.id_filtrage_fmpa_courant == 'global') {
					let est_valide = true
					for (const fmpa of this.fmpas_courantes) {
						if(!this.estListeValidee(fmpa.liste, membre)) {
							est_valide = false
							break
						}
					}
					return est_valide
				}
				else {
					const fmpa_filtree = this.fmpas_courantes.find( fmpa => fmpa.categorie == this.id_filtrage_fmpa_courant)
					if(fmpa_filtree) {
						return this.estListeValidee(fmpa_filtree.liste, membre)
					}
					else{
						return false
					}
				}
			},
			estListeValidee(liste, membre = null) {
				//	retourne true si l'ensemble des themes de la liste est validé
				let valide = true
				for (const theme of liste) {
					if(!this.estThemeValide(theme, membre)) {
						valide = false
						break
					}
				}
				return valide
			},
			estSPPSPVonly(theme) {
				//	renvoie true si le the ne s'applique qu'au SPV ou qu'au SPP
				return (theme.pour_spp && !theme.pour_spv) || (!theme.pour_spp && theme.pour_spv)
			},
			estThemeValide(theme, membre = null) {
				//	retourne true si le theme est validé:
				//	 -duree effectuée >= duree prévue ou apte 
				//	ET si le theme concerne le SP (certains themes sont uniquement pour SPP ou SPV))
				//	OU si le thème est non SSUAP et si la validation a été forcée
				
				//	on définit le membre concerné
				const membre_a_utiliser = membre ? membre : this.membreCourant

				//	on renvoie true si le theme ne concerne pas le membre
				if((!theme.pour_spp && membre_a_utiliser.statut == 1) || (!theme.pour_spv && membre_a_utiliser.statut == 0)) {
					return true
				}

				//	arrivé ici le thème concerne le membre

				//	si le theme requiert une durée
				if(theme.type.duree_requise) {
					const duree_prevue = moment.duration(theme.duree_prevue).asMinutes()	// durée en minutes
					const duree_realisee = moment.duration(this.getDureeRealiseeTheme(theme, membre)).asMinutes()
					return  duree_realisee >= duree_prevue || this.estThemeForce(theme)
				}

				//	sinon on regarde si la personne a été mise apte sur la dernière réalisation faite
				else {
					// on filtre les réalisations du membre courant
					//const membre_a_utiliser = membre ? membre : this.membreCourant
				
					//	on ne prend en compte que les réalisation du membr et on prend en compte celles ralisées hors jour de manoeuvre si elles sont comptées
					const liste_filtree = theme.realisations.filter( realisation => realisation.membre_centre.id_user == membre_a_utiliser.id_user && (!realisation.hors_manoeuvre || (realisation.hors_manoeuvre && realisation.hors_manoeuvre_compte)))

					if(liste_filtree.length == 0) return false
					return liste_filtree[liste_filtree.length - 1].est_apte 
				}
				
			},
			estThemeForce(theme) {
				if (this.membreCourant == null) return false
				//	renvoie true si la validation du thème a été forcée
				if(theme.realisations.length == 0) return false
				//return theme.realisations.findIndex(realisation => realisation.theme_force && realisation.membre_centre.id_centre == this.centre_courant.id) > -1
				return theme.realisations.findIndex(realisation => realisation.theme_force && realisation.membre_centre.id_user == this.membreCourant.id_user) > -1
			},
			fetchFMPA(annee) {
				//	on active les 2 loading si aucun ne l'est (cas du chargement initial)
				if(!this.chargement_annee_precedente && !this.chargement_annee_suivante) {
					this.chargement_annee_suivante = true
					this.chargement_annee_precedente = true
					
                }
                axios.post('api/fmpas/fmpa_centre', { 
                        annee: annee, 
						id_membre: this.$store.getters.membreCourant.id,
						id_centre: this.id_centre_selectionne
                    })
                    .then( (reponse) => {
						this.annee_courante_chargee = reponse.data.fmpas.annee
						
						const fmpa_groupee = groupBy(reponse.data.fmpas.themes, (theme) => theme.type.sigle)
						let liste_fmpa = []
						for (const [key, fmpa] of Object.entries(fmpa_groupee)) {
							liste_fmpa.push({
								categorie: key,
								duree_requise: fmpa[0].type.duree_requise,
								liste: fmpa
							})
						}
						this.id_fmpa_courante = reponse.data.fmpas.id ?? null
						this.fmpas_courantes = liste_fmpa.sort((a,b) => a.categorie.localeCompare(b.categorie))
						this.annee = annee

						//	format pour FMPA affiché en timeline
						this.fmpas_courantes_timeline_globale = reponse.data.fmpa_timeline

						//	création de la liste de tous les thèmes
						let liste = []
						for (const categorie of this.fmpas_courantes) {
							for (const theme of categorie.liste) {
								liste.push({
									id: theme.id,
									id_type: theme.id_type,
									titre: theme.titre,
									description: theme.description,
									pour_spv: theme.pour_spv,
									pour_spp: theme.pour_spp,
									categorie: categorie.categorie,
									duree_prevue: moment(theme.duree_prevue, 'HH:mm:ss').format('HH:mm'),
									departemental: theme.departemental,
									nbre_realisations: theme.realisations.length
								})
							}
						}
						this.liste_themes_courant = liste
						
						
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement des FMPA');
                    })
                    .then(() => {
						this.chargement_annee_suivante = false
						this.chargement_annee_precedente = false
                    })
			},
			formatDate(date) {
				if(!date) return null
				return moment(date).format('DD/MM/YYYY')
			},
			formatDuree(duree) {
				const duree_minutes = moment.duration(duree).asMinutes()
				const nbre_heures = Math.floor(duree_minutes / 60)
				const nbre_minutes = duree_minutes % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
			},
			formatDureePrevueTheme(theme) {
				const somme = moment.duration(theme.duree_prevue).asMinutes()
				
				const nbre_heures = Math.floor(somme / 60)
				const nbre_minutes = somme % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
			},
			generer_tableau_excel() {
				this.chargement_excel = true
				axios({
                    method: 'post',
                    url: 'api/fmpas/generation_tableau',
					data: { id_fmpa: this.id_fmpa_courante,
							id_centre: this.centre_courant.id
					},
                    responseType: 'blob'
                    })
                    .then((reponse) => {
                        saveAs(new Blob([reponse.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), 'FMPA '+this.annee+'.xlsx')
                        this.snackbar('success','Tableau Excel créé');
                    })
                    .catch((error) => {
                        this.snackbar('error','Erreur lors de la création du tableau Excel')
                    })
                    .then((reponse) => {
                        this.chargement_excel = false
                    })
			},
			getChildPayloadRealisation(index) {
				return this.formulaireRealisationFMPAMultiple.liste_realisations[index]
			},
			getChildPayloadTheme(index) {
                return this.liste_themes_fmpa_disponibles[index]
            },
			getCouleurCategorie(categorie) {
				let couleur = '#9E9E9E' 	//grey
				switch (categorie) {
					case 'DIV':
						couleur = '#AFB42B'
						break;
					case 'INC':
						couleur = '#FFA000'
						break;
					case 'SR':
						couleur = '#3949AB'
						break;
					case 'SSUAP':
						couleur = '#D32F2F'
						break;
					default:

						break;
				}
				return couleur
			},
			getDureePrevueListe(liste) {
				//	retoure la durée totale prévue des éléments de la liste
				let somme = 0   //  nbre de minutes
                for (const theme of liste) {
                    if(theme.duree_prevue) {   
                        somme = somme + moment.duration(theme.duree_prevue).asMinutes()
                    }
                }
                const nbre_heures = Math.floor(somme / 60)
				const nbre_minutes = somme % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
                //return nbre_heures.toString().padStart(2, '0') + ':' + nbre_minutes.toString().padStart(2, '0')
			},
			getDureeRealiseeListe(liste) {
				//	retourne la somme des durées réalisées
				let somme = 0
				for (const theme of liste) {
					somme = somme + moment.duration(this.getDureeRealiseeTheme(theme)).asMinutes()
				}
				const nbre_heures = Math.floor(somme / 60)
                const nbre_minutes = somme % 60
				
				return parseInt(nbre_heures) + ':' + nbre_minutes.toString().padStart(2, '0')
				//return nbre_heures.toString().padStart(2, '0') + ':' + nbre_minutes.toString().padStart(2, '0')

			},
			getDureeRealiseeTheme(theme, membre = null) {
				//	retourne la durée réalisée du theme
				
				// on filtre les réalisations du membre courant
				const membre_a_utiliser = membre ? membre : this.membreCourant
				
				//	on ne prend en compte que les réalisation du membre et on prend en compte celles ralisées hors jour de manoeuvre si elles sont comptées
				const liste_filtree = theme.realisations.filter( realisation => realisation.membre_centre.id_user == membre_a_utiliser.id_user && (!realisation.hors_manoeuvre || (realisation.hors_manoeuvre && realisation.hors_manoeuvre_compte)))
				
				let somme = 0
				for (const realisation of liste_filtree) {
					somme = somme + moment.duration(realisation.duree_effectuee).asMinutes()
				}
				const nbre_heures = Math.floor(somme / 60)
                const nbre_minutes = somme % 60
				
				return parseInt(nbre_heures) + ':' + nbre_minutes.toString().padStart(2, '0')
				//return nbre_heures.toString().padStart(2, '0') + ':' + nbre_minutes.toString().padStart(2, '0')
			},
			getIconeCategorie(categorie) {
				let icone = ''
				switch (categorie) {
					case 'DIV':
						icone = 'mdi-traffic-cone'
						break;
					case 'INC':
						icone = 'mdi-fire'
						break;
					case 'SR':
						icone = 'mdi-car-sports'
						break;
					case 'SSUAP':
						icone = 'mdi-hospital-box'
						break;
					case 'CP':
						icone = 'mdi-fire-extinguisher'
						break;
					default:

						break;
				}
				return icone
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
            },
			listeRealisationsUser(theme) {
				//	on ne prend en compte que les réalisation du user
				return theme.realisations.filter( realisation => realisation.membre_centre.id_user == this.membreCourant.id_user)
			},
			onClickAjoutFMPAIndiv(theme) {
				this.formulaireRealisationFMPA.id_realisation = null
				this.formulaireRealisationFMPA.id_theme = theme.id
				this.formulaireRealisationFMPA.id_type = theme.id_type
				this.formulaireRealisationFMPA.titre = 'Ajout ligne '+theme.titre
				this.formulaireRealisationFMPA.est_apte = theme.id_type != 4 ? false : undefined
				this.formulaireRealisationFMPA.duree = '00:00'
				this.formulaireRealisationFMPA.hors_manoeuvre = false
				this.openDialogueRealisationFMPA = true
			},
			onClickAjoutFMPAMultiple() {
				this.formulaireRealisationFMPAMultiple.id_fmpa = this.id_fmpa_courante
				this.formulaireRealisationFMPAMultiple.liste_membres = []
				this.formulaireRealisationFMPAMultiple.liste_realisations = []
				this.liste_themes_fmpa_disponibles = [...this.liste_themes_fmpa_courante]
				this.openDialogueRealisationFMPAMultiple = true
			},
			onClickAjoutLigneMultiple() {
				this.formulaireRealisationFMPAMultiple.liste_realisations.push({
					//id_theme: null,
					theme: null,
					date: null,
					duree: '00:00',
					est_apte: undefined,
					hors_manoeuvre: false,
					commentaire: null,
				});
			},
			onClickAjoutTheme() {
				this.openDialogueCreerTheme = true
			},
			onClickAnnuleCreerFMPA() {
				this.openDialogueCreerFMPA = false

				this.formulaireFMPA.id_fmpa = null
				this.formulaireFMPA.id_membre = null,
				this.formulaireFMPA.id_centre = null,
				this.formulaireFMPA.ids_themes_supprimes = [],
				this.formulaireFMPA.themes = []
				this.formulaireFMPA.titre = ''
			
			},
			onClickAnnuleCreerTheme() {
				this.formulaireTheme.id = null
				this.formulaireTheme.id_type = null
				this.formulaireTheme.titre = ''
				this.formulaireTheme.description = ''
				this.formulaireTheme.duree_prevue = '00:00'
				this.formulaireTheme.pour_spv = false
				this.formulaireTheme.pour_spp = false
				this.$v.formulaireTheme.$reset()
				this.openDialogueCreerTheme = false
			},
			onClickAnnuleRealisationFMPA() {
				this.openDialogueRealisationFMPA = false
				this.formulaireRealisationFMPA.id_realisation = null
				this.formulaireRealisationFMPA.id_theme = null
				this.formulaireRealisationFMPA.id_type = null
				this.formulaireRealisationFMPA.duree = '00:00'
				this.formulaireRealisationFMPA.date = null
				this.formulaireRealisationFMPA.commentaire = null
				this.formulaireRealisationFMPA.est_apte = undefined
				this.formulaireRealisationFMPA.titre = ''
				this.formulaireRealisationFMPA.hors_manoeuvre = false
			},
			onClickAnnuleRealisationFMPAMultiple() {
				this.openDialogueRealisationFMPAMultiple = false
				this.formulaireRealisationFMPAMultiple.id_fmpa = null
				this.formulaireRealisationFMPAMultiple.liste_membres = []
				this.formulaireRealisationFMPAMultiple.liste_realisations = []
				this.liste_themes_fmpa_disponibles = [...this.liste_themes_fmpa_courante]
			},
			onClickComptabiliserFMPAIndiv(realisation){
				axios.post('api/fmpas/comptabiliser_hors_manoeuvre', { 
						id_membre: this.$store.getters.membreCourant.id,
						id_realisation: realisation.id
						})
						.then((reponse) => {
							this.snackbar('success',reponse.data.resultat);
							this.fetchFMPA(this.annee)
						})
						.catch((error) => {
							this.snackbar('error', 'Erreur lors de la prise en compte');
						})
						.then(() => {
							
						})
			},
			onClickCreerFMPA() {
				this.formulaireFMPA.titre = 'Création FMPA'
				this.formulaireFMPA.id_fmpa = null
				this.formulaireFMPA.id_membre = this.$store.getters.membreCourant.id
				this.formulaireFMPA.id_centre = this.id_centre_selectionne
				this.formulaireFMPA.ids_themes_supprimes = []
				this.formulaireFMPA.themes = []
				this.openDialogueCreerFMPA = true
			},
			onClickDeleteSPVListe(membre) {
				if(membre) {
					const index = this.formulaireRealisationFMPAMultiple.liste_membres.findIndex( spv => spv.id == membre.id)
					
					if(index > -1) {
						this.formulaireRealisationFMPAMultiple.liste_membres.splice(index,1)
					}
				}
			},
			onClickDeleteRealisation(theme, index) {
				if(index > -1) {
					this.formulaireRealisationFMPAMultiple.liste_realisations.splice(index, 1)
					
					//	pour le remettre au même endroit qu'initialement, on cherche sa position dans la list d'origine
					const index_origine = this.liste_themes_fmpa_courante.findIndex(theme_origine => theme_origine.id_theme == theme.id_theme)
					if(index_origine  > - 1 ){
						this.liste_themes_fmpa_disponibles.splice(index_origine, 0, theme)
					}
					else {
						this.liste_themes_fmpa_disponibles.push(theme)
					}
				}
			},
			onClickDeleteTheme(theme, index) {
				if(theme.$model.id != null) {
					this.formulaireFMPA.ids_themes_supprimes.push(theme.$model.id)
				}
				this.formulaireFMPA.themes.splice(index,1)
			},
			onClickForcerValidationTheme(theme) {
				axios.post('api/fmpas/forcer_validation_theme', { 
						id_membre: this.$store.getters.membreCourant.id,
						id_theme: theme.id,
						id_membre_concerne: this.membreCourant.id,
						a_forcer: !this.estThemeForce(theme)
						})
						.then((reponse) => {
							this.snackbar('success',reponse.data.resultat);
							this.fetchFMPA(this.annee)
						})
						.catch((error) => {
							this.snackbar('error', 'Erreur lors de la validation');
						})
						.then(() => {
							
						})
			},
			onClickModifTheme(theme) {
				this.formulaireTheme.id = theme.id
				this.formulaireTheme.id_type = theme.id_type
				this.formulaireTheme.titre = theme.titre
				this.formulaireTheme.description = theme.description
				this.formulaireTheme.duree_prevue = moment(theme.duree_prevue, 'HH:mm:ss').format('HH:mm')
				this.formulaireTheme.pour_spv = theme.pour_spv
				this.formulaireTheme.pour_spp = theme.pour_spp
				this.openDialogueCreerTheme = true
			},
			onClickNomMembreDetail() {
				//	on ouvre le drawer si en mode affichage portable
				if(this.affichagePortable) this.openListeSPVDrawer = true
			},
			onClickSupprFMPA() {
				this.dialogue_confirmation.titre = 'Suppression'
				this.dialogue_confirmation.texte = 'Supprimer la FMPA '+this.annee +' ?'
				this.dialogue_confirmation.action = 'suppr_fmpa'
				this.dialogue_confirmation.id_element = this.id_fmpa_courante
				this.openDialogueConfirmation = true
			},
			onClickSupprFMPAIndiv(realisation, theme) {
				this.dialogue_confirmation.titre = 'Suppression'

				if(theme.id_type == 4) {	// si SSUAP
					let texte_aptitude = realisation.est_apte ? 'APTE' : 'INAPTE'
					this.dialogue_confirmation.texte = 'Supprimer la ligne: '+theme.description+' : '+texte_aptitude+' du '+ moment(realisation.date).format('DD/MM/YYYY') +' ?'
				}
				else {
					this.dialogue_confirmation.texte = 'Supprimer la ligne: '+theme.description+' : '+moment(realisation.duree_effectuee, 'HH:mm:ss').format('HH:mm') +' le '+ moment(realisation.date).format('DD/MM/YYYY') +' ?'
				}
				
				this.dialogue_confirmation.action = 'suppr_realisation'
				this.dialogue_confirmation.id_element = realisation.id
				this.openDialogueConfirmation = true
			},
			onClickSupprTheme(theme) {
				this.dialogue_confirmation.titre = 'Suppression'
				this.dialogue_confirmation.texte = 'Supprimer le thème '+theme.titre +' ?'
				this.dialogue_confirmation.action = 'suppr_theme'
				this.dialogue_confirmation.id_element = theme.id
				this.openDialogueConfirmation = true
			},
			onSelectMembre(membre) {
				this.membreCourant = membre
				this.openListeSPVDrawer = false		//	cas d'un affichage portable
			},
			onClickUpdateFMPA() {
				this.formulaireFMPA.id_fmpa = this.id_fmpa_courante
				this.formulaireFMPA.id_membre = this.$store.getters.membreCourant.id
				this.formulaireFMPA.id_centre = this.id_centre_selectionne
				this.formulaireFMPA.ids_themes_supprimes = []
								
				//	création de la liste de tous les thèmes
				let liste = []
				for (const categorie of this.fmpas_courantes) {
					for (const theme of categorie.liste) {
						liste.push({
							id: theme.id,
							id_type: theme.id_type,
							titre: theme.titre,
							description: theme.description,
							pour_spv: theme.pour_spv,
							pour_spp: theme.pour_spp,
							duree_prevue: moment(theme.duree_prevue, 'HH:mm:ss').format('HH:mm')
						})
					}
				}
				this.formulaireFMPA.themes = liste

				this.formulaireFMPA.titre = 'Modification FMPA'
				this.openDialogueCreerFMPA = true
			},
			onClickUpdateFMPAIndiv(realisation, theme) {
				this.formulaireRealisationFMPA.id_realisation = realisation.id
				this.formulaireRealisationFMPA.id_theme = theme.id
				this.formulaireRealisationFMPA.id_type = theme.id_type
				this.formulaireRealisationFMPA.est_apte = realisation.est_apte
				this.formulaireRealisationFMPA.duree = moment(realisation.duree_effectuee, 'HH:mm:ss').format('HH:mm')
				this.formulaireRealisationFMPA.date = realisation.date
				this.formulaireRealisationFMPA.commentaire = realisation.commentaire
				this.formulaireRealisationFMPA.titre = 'Modification ligne '+theme.titre
				this.formulaireRealisationFMPA.hors_manoeuvre = realisation.hors_manoeuvre
				this.openDialogueRealisationFMPA = true
			},
			onDrop(dropResult) {
				//	appelé lorsqu'une réalisation est déposée ou retiré de la zone réalisation
				const { removedIndex, addedIndex, payload } = dropResult

				//	payload: 	categorie: xx
				//				description: xx
				//				id_theme: xx
				//				id_type: xx
				//				ouverture_menu_date: true/false
				//				pour_spp: true/false
				//				pour spv: true/false
				//				titre: xx

				//console.log('action sur liste realisations', dropResult)

				//  s'il n'y a ni ajout ni retrait on ne fait rien
                if (removedIndex === null && addedIndex === null) {

				}
				//	si déplacement dans la liste on ne fait rien
				else if(removedIndex !== null && addedIndex !== null) {

				}
				//	si retrait de la liste
				else if(removedIndex !== null) {
					this.formulaireRealisationFMPAMultiple.liste_realisations.splice(removedIndex, 1)
				}
				//  on ajoute l'élément dans la liste destination
				else if(addedIndex !== null) {
					const realisation = {
						theme: payload,
						date: null,
						duree: '00:00',
						est_apte: payload.id_type != 4 ? false : undefined,
						hors_manoeuvre: false,
						commentaire: null
					}
					this.formulaireRealisationFMPAMultiple.liste_realisations.splice(addedIndex, 0, realisation)
				}
			},
			onDropTheme(dropResult) {
				//	appelé lorsqu'un thème est déposé ou retiré de la zone theme
				const { removedIndex, addedIndex, payload } = dropResult

				//console.log('action sur liste theme', dropResult)

				//  s'il n'y a ni ajout ni retrait on ne fait rien
                if (removedIndex === null && addedIndex === null) {

				}
				//	si déplacement dans la liste on ne fait rien
				else if(removedIndex !== null && addedIndex !== null) {

				}
				//	si retrait de la liste
				else if(removedIndex !== null) {
					this.liste_themes_fmpa_disponibles.splice(removedIndex, 1)
				}
				//	si on ajoute le theme
				else if(addedIndex !== null) {
					let retour_theme = payload.theme
					//	on reinitialise les valeurs
					//retour_theme.ouverture_menu_date = false
					this.liste_themes_fmpa_disponibles.splice(addedIndex, 0, retour_theme)
				}
                     
			},
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			texte_membre(membre) {
				return membre?.user?.nom +' '+membre?.user?.prenom
			},
			validConfirmation() {
				
				let chemin = ''
				let message_erreur = ''
				
				switch (this.dialogue_confirmation.action) {
					case 'suppr_fmpa':
						chemin = 'api/fmpas/suppression'
						message_erreur = 'Erreur lors de la suppression de la FMPA'
						break;
					case 'suppr_realisation':
						chemin = 'api/fmpas/suppression_realisation'
						message_erreur = 'Erreur lors de la suppression de la ligne'
						break;
					case 'suppr_theme':
						chemin = 'api/fmpas/suppr_theme_cs'
						message_erreur = 'Erreur lors de la suppression du thème'
						break;
					default:
						break;
				}

				axios.post(chemin, { 
					id_membre: this.$store.getters.membreCourant.id,
					...this.dialogue_confirmation
					})
                    .then((reponse) => {
						this.snackbar('success',reponse.data.resultat);
						if(this.dialogue_confirmation.action == 'suppr_fmpa') {
							this.id_fmpa_courante = null
							this.fmpas_courantes = []
						}
						else if(this.dialogue_confirmation.action == 'suppr_realisation') {
							this.fetchFMPA(this.annee)
						}
						else if(this.dialogue_confirmation.action == 'suppr_theme') {
							this.fetchFMPA(this.annee)
						}
						
						
						
                    })
                    .catch((error) => {
                        this.snackbar('error', message_erreur);
                    })
                    .then(() => {
                        this.chargement_confirmation = false
                        this.annuleConfirmation()
                    })
			},
		},
		watch: {
            centre_courant() {
                
                //  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces gestion fmpa')) {
                    this.$router.push('/')
                }
                else {
					this.id_centre_selectionne = this.$store.getters.membreCourant.id_centre
                }
			},
			id_centre_selectionne() {
				this.membreCourant = null
				this.$store.dispatch('getAnnuaire', this.id_centre_selectionne)
				this.$store.dispatch('getCategoriesFMPA')
				this.annee = parseInt(moment().format('YYYY'))
				this.fetchFMPA(this.annee)
			}
        },
		validations: {
            formulaireFMPA: {
				themes: {
					$each: {
						id: {

						},
						id_type: {
							required
						},
						titre: {
							required
						},
						description: {
							required
						},
						duree_prevue: {
							required,
							formatHeure(duree_prevue, theme) {
								return /^(0?[1-9]|[0-9][0-9]):[0-5][0-9]$/.test(duree_prevue) && (theme.id_type == 4 || (theme.id_type != 4 && duree_prevue != '00:00'))
							},
							// nomNul(duree_prevue) {
							// 	return duree_prevue != '00:00'
							// }
						},
						pour_spv: {

						},
						pour_spp: {

						}
					}
					
				}
			},
			formulaireTheme: {
				id_type: {
                    required,                
                },
				titre: {
                    required,                
                },
				description: {
                    required,                
                },
				duree_prevue: {
					required, 
					formatHeure(duree_prevue) {
						return /^(0?[1-9]|[0-9][0-9]):[0-5][0-9]$/.test(duree_prevue) && (this.formulaireTheme.id_type == 4 || (this.formulaireTheme.id_type != 4 && duree_prevue != '00:00'))
					},               
                },
			},
			formulaireRealisationFMPA: {
				duree: {
					required,
					formatHeure(duree_prevue) {
						return /^(0?[1-9]|[0-9][0-9]):[0-5][0-9]$/.test(duree_prevue) && (this.formulaireRealisationFMPA.id_type == 4 || (this.formulaireRealisationFMPA.id_type != 4 && duree_prevue != '00:00'))
					},
				},
				date: {
					required
				},
				est_apte: {
					required
				}
			},
			formulaireRealisationFMPAMultiple: {
				liste_membres: {
					required,
					nonVide(liste_membres) {
						return liste_membres.length > 0
					}
				},
				liste_realisations: {
					$each: {
						duree: {
							required,
							formatHeure(duree_prevue, realisation) {
								return /^(0?[1-9]|[0-9][0-9]):[0-5][0-9]$/.test(duree_prevue) && realisation.theme && (realisation.theme.id_type == 4 || (realisation.theme.id_type != 4 && duree_prevue != '00:00'))
							},
						},
						date: {
							required
						},
						est_apte: {
							required
						},
						theme: {
							id_theme: {
								required
							}
							
						
						},
						commentaire: {
							
						},
						hors_manoeuvre: {

						}
					}
				}
			}
        },
	}
</script>

<style scoped>

.card_liste_spv_multiple {
	min-height: 350px;
	max-height: 350px;
	overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.contenu_panels_pc {
	max-height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;
}

.liste_membres {
    max-height: calc(100vh - 305px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

.liste_membres_choix_centre {
	max-height: calc(100vh - 355px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

.liste_membres_saisie_multiple {
    max-height: calc(100vh - 400px);
	/* min-height: 450px;
	max-height: 450px; */
    /* min-height: 290px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

/* .liste_membres_portable {
    max-height: calc(60vh - 290px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
} */

.liste_spv_fmpa_portable {
	max-height: calc(100vh - 185px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

.liste_spv_fmpa_portable_choix_centre {
	max-height: calc(100vh - 232px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

.liste_theme_fmpa {
    background-color: transparent;
}

.membreFMPA_ok {
    background-color: #C5E1A5;
}

.membreFMPA_nok {
    background-color: #EF9A9A;
}

.membreSelectionne {
    background-color: #EDE7F6;
}

.v-divider {
	min-width: 2px !important;
	background-color: white !important;
}

#zone_liste_drawer {
	max-height: calc(100vh - 55px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}


.zone_liste_theme_dialogue_update {
	max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}




.zone_theme_saisie {
	max-height: calc(100vh - 370px);
	/* min-height: 480px;
	max-height: 480px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

</style>

<style>


.bouton_apte {
	color:#8BC34A !important;
}

.bouton_inapte {
	color:#F44336 !important;
}

.fmpa_pc_gestion {
	border-bottom: 1px solid #E0E0E0 !important;
}

.contenu_liste_theme_fmpa_gestion .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    
}

.zone_realisation_fmpa_multiple {
	max-height: calc(100vh - 370px);
	min-height: calc(100vh - 500px);
	/* min-height: 480px !important;
	max-height: 480px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

</style>